import { CountryCode, LanguageCodeEnum } from "@graphql";
import { isValidPhoneNumber } from "libphonenumber-js";
import { z } from "zod";

export const languageCodeSchema = z.nativeEnum(LanguageCodeEnum);

export const countryCodeSchema = z.nativeEnum(CountryCode);

export const phoneSchema = z.string().refine(isValidPhoneNumber, "zodI18n.validations.phone");

export const metadataInputSchema = z.object({
  key: z.string(),
  value: z.string(),
});
